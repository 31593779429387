import Countdown from 'react-countdown';

import './App.css';
import { Clock } from './clock';

import couple from './wedding_pic1.jpg';
import amazon from './amazon.png';
// import bbb from './bbb.jpeg';
import target from './target.jpg';

function App() {
  return (
    <div>
      <div className='title'>
        <div className='name-container'>
          <div className='name-title'>Megan</div>
          <div className='name-and'>and</div>
          <div className='name-title'>Leo</div>
        </div>
      </div>

      <div className='couple'>
      <img className='couple-img' src={couple} alt='leo and megan posing at a previous wedding' />
      </div>

      <div className='mb-50 join-container'>
        {/* <div className='font-40 join'>
          please join us for our wedding celebration on
        </div> */}

        <div className='font-40 date'>
          November 20, 2023
        </div>
        <div className='font-40 mb-50 location'>
          Raleigh, NC
        </div>

        {/* <div className='font-40 reg-title'>Information</div>
        <div className='info w-50 mb-50'>
          <div className='w-50'>
            We have a reserved room block for April 1st, 2022 – April 3rd, 2022 at the <a target='_blank' without="true" rel="noreferrer" href='https://www.google.com/maps/place/Hampton+Inn+%26+Suites+Raleigh+Midtown/@35.811632,-78.6296258,14.35z/data=!3m1!5s0x89ac58c497fe6967:0xefe4f490f9a78571!4m8!3m7!1s0x89ac597b0d8810e1:0x717e7b58204f6c13!5m2!4m1!1i2!8m2!3d35.8218547!4d-78.6188866' >Hampton Inn &amp; Suites Raleigh Midtown</a>. Please follow <a target='_blank' without="true" rel="noreferrer" href='https://www.hilton.com/en/attend-my-event/rdurdhx-hmw-962113c5-2b82-487b-bf30-9845640f3c44/'>this link</a> to book your stay. You may also call the hotel directly at <a href="tel:+9198254000">(919) 825-4000</a> to access the room rate.
          </div>

          <div className='w-50'>
            Transportation to and from the designated hotel and venue at Campbell Lodge will be provided by Carolina Livery for guests for free.  The bus will be available to transport guests to and from each location per guest request until the send-off, which will be the final trip back to the hotel. The departure time to arrive at the venue for the ceremony is at <b>3:45pm</b>. Ample parking is available at the venue location.
          </div>

        </div> */}

        {/* <div className='font-40 reg-title'>Registry</div>
        <div className='mb-50 links'>
          <div className='amazon'>
            <a target='_blank' without="true" rel="noreferrer" href='https://www.amazon.com/wedding/leonardo-merza-megan-hayworth-raleigh-april-2022/registry/2CKYVNY9BUZIX'>
            <img src={amazon} alt='amazon swoosh'/>
            </a>
          </div>

          <div className='bbb'>
            <a target='_blank' without="true" rel="noreferrer" href='https://www.bedbathandbeyond.com/store/giftregistry/viewregistryguest/550889549?eventType=Wedding'>
            <img src={bbb} alt='bed bath and beyond'/>
            </a>
          </div>
        </div> */}

        <div className='font-40 reg-title'>Baby Registry</div>
        <div className='mb-50 links'>
          <div className='amazon'>
            <a target='_blank' without="true" rel="noreferrer" href='https://www.amazon.com/baby-reg/leo-merza-megan-hayworth-november-2023-raleigh/V1GTGMUYB2V2'>
            <img src={amazon} alt='amazon swoosh'/>
            </a>
          </div>

          <div className='bbb'>
            <a target='_blank' without="true" rel="noreferrer" href='https://www.target.com/gift-registry/gift/baby-merza'>
            <img src={target} alt='target'/>
            </a>
          </div>
        </div>

        {/* wedding 4/2/2022 */}
        <Countdown date={1648928700000} renderer={Clock}/>
        {/* baby 11/20/2023 */}
        <Countdown date={1700456400000} renderer={Clock}/>
      </div>
    </div>
  );
}

export default App;
