

export function Clock({ days, hours, minutes, seconds, completed }){
  if(completed) return null;

  return (
    <div id="clockdiv">
        <div><span className="days">{days}</span><div className="smalltext">Days</div></div>
        <div><span className="hours">{hours}</span><div className="smalltext">Hours</div></div>
        <div><span className="minutes">{minutes}</span><div className="smalltext">Minutes</div></div>
        <div><span className="seconds">{seconds}</span><div className="smalltext">Seconds</div></div>
    </div>
  )
}